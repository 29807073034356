var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-z-20 tw-h-full tw-w-full tw-absolute"},[_c('div',{staticClass:"tw-transition-all tw-duration-300 tw-bg-gray-800 tw-h-full tw-p-6 tw-px-3 tw-fixed tw-z-30 tw-w-screen sm:tw-w-72",class:{
      'sm:tw--ml-72 tw--ml-96 tw-opacity-5': !_vm.premenu,
      'tw-ml-0': _vm.premenu,
    }},[_c('div',{staticClass:"tw-text-lg tw-font-normal tw-text-center tw-flex tw-justify-center tw-items-center tw-py-5"},[_c('div',{staticClass:"tw-pr-2 tw--ml-5"},[_c('svg',{staticClass:"tw-h-10 tw-w-10 tw-text-pink-200",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z","clip-rule":"evenodd"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z","clip-rule":"evenodd"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z","clip-rule":"evenodd"}})])]),_c('div',{staticClass:"tw-text-white tw-font-bold"},[_vm._v("Dashboard")])]),_c('div',{staticClass:"tw-mt-5"},[_vm._l((_vm.listaMenu),function(item,index){return _c('div',{key:index},[_c('button',{staticClass:"rotaPadrao",class:{
            'tw-bg-opacity-50 tw-bg-black': _vm.$route.path.includes(item.link),
          },on:{"click":function($event){return _vm.acesso(item)}}},[_c('div',{staticClass:"tw-px-2"},[_c('v-icon',{staticClass:"tw-text-pink-200",attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))])],1),_c('div',{staticClass:"tw-font-normal tw-text-md tw-font-sans tw-tracking-wide tw-text-gray-200 tw-mt-1"},[_vm._v(" "+_vm._s(item.nome)+" ")])])])}),_c('div',{staticClass:"tw-absolute tw-bottom-3 tw-w-11/12 text-center mx-auto"},[_c('button',{staticClass:"rotaPadrao",class:{
            'tw-bg-opacity-50 tw-bg-black': _vm.$route.path.includes(),
          },on:{"click":function($event){return _vm.logout()}}},[_c('div',{staticClass:"tw-px-2"},[_c('v-icon',{staticClass:"tw-text-pink-200",attrs:{"size":"20"}},[_vm._v(_vm._s("mdi-logout"))])],1),_c('div',{staticClass:"tw-font-normal tw-text-md tw-font-sans tw-tracking-wide tw-text-gray-200 tw-mt-1"},[_vm._v(" Logout ")])])])],2)]),_c('div',{staticClass:"tw-bg-transparent tw-opacity-10 tw-h-full tw-w-full",on:{"click":function($event){return _vm.closeme()}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }