<template>
  <div class="tw-p-4 tw-w-screen tw-bg-white tw-shadow-sm tw-relative">
    <div class="tw-grid tw-grid-cols-8 tw-items-center">
      <div class="tw-col-span-3 tw-align-middle tw-items-center">
        <v-btn text @click="abrirMenu()">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <div class="tw-col-span-2 tw-align-middle">
        <img
          src="@/assets/logo.png"
          class="tw-w-24 tw-mx-auto tw-align-middle"
        />
      </div>
      <div class="tw-col-span-3 tw-text-right tw-px-1 tw-hidden sm:tw-block">
        Bem vindo, <span class="tw-font-bold">{{ getUserName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    abrirMenu() {
      this.$store.commit("dashboard/setMenu", true);
    },
  },
  computed: {
    getUserName() {
      let user = this.$store.getters["user/getUser"];
      return user.nome ? user.nome : "";
    },
  },
};
</script>
