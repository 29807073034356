<template>
  <vLoadingData @loadSuccess="successData" v-if="dataLoading"></vLoadingData>
  <vDashboard v-else />
</template>

<script>
import vLoadingData from "@/views/dashboard/LoadingData";
import vDashboard from "./dashboard.vue";
export default {
  components: { vLoadingData, vDashboard },
  data() {
    return {
      dataLoading: true,
    };
  },
  methods: {
    successData() {
      this.dataLoading = false;
    },
  },
};
</script>

<style></style>
