<template>
  <div
    class="tw-fixed tw-h-full tw-w-full tw-top-0 tw-bottom-0 tw-bg-gray-700 tw-items-center tw-flex tw-justify-center"
  >
    <div
      class="tw-bg-white tw-mx-2 tw-rounded sm:tw-mx-auto tw-p-5 tw-flex-1 tw-max-w-xs sm:tw-max-w-sm tw-text-center"
    >
      <div class="tw-font-bold tw-text-lg">Carregando</div>
      <div class="tw-text-gray-500 tw-mt-3">
        Aguarde o carregamento dos dados
      </div>
      <div class="tw-p-2">
        <v-progress-linear :value="valorCarregamento"></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
import { getDoc, doc } from "@firebase/firestore";
import { db } from "@/service/firebase.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      timer: null,
      contagem: 0,
      maxContagem: 5,
      ms: 2000,
      valorCarregamento: 0,
    };
  },
  beforeMount() {
    this.IniciarContagem();
  },
  computed: {
    ...mapGetters({
      getAuth: "user/getAuth",
    }),
  },
  methods: {
    async LoadingTotens() {
      await this.$store.dispatch("dashboard/loadTotens");
      this.valorCarregamento += 50;
      setTimeout(() => {
        this.FinalizarProcesso();
      }, 1000);
    },
    async LoadingUser() {
      let token = this.$store.getters["user/getToken"];
      let userRef = doc(db, "users", `${token}`);
      const snap = await getDoc(userRef);
      if (snap.exists()) {
        let payload = snap.data();
        this.$store.commit("user/setUserPayload", {
          nome: payload.nome,
          role: payload.role,
          franqueado_id: payload.franqueado_id,
          email: payload.email,
          status: payload.status,
        });
        this.valorCarregamento += 50;
        setTimeout(() => {
          this.LoadingTotens();
        }, 1000);
      } else {
        this.ErrorUser();
      }
    },
    StartDataFetch() {
      this.LoadingUser();
    },
    ErrorUser() {
      this.$router.push("/login");
      this.$root.errorModal(
        "Ocorreu um erro durante o carregamento, tente novamente"
      );
    },
    IniciarContagem() {
      this.timer = setTimeout(() => {
        if (this.getAuth) {
          this.valorCarregamento += 25;
          this.StartDataFetch();
        } else {
          if (this.contagem >= this.maxContagem) {
            this.$router.push("/login");
            return false;
          }
          this.contagem += 1;
          this.IniciarContagem();
        }
      }, this.ms);
    },
    FinalizarProcesso() {
      this.$emit("loadSuccess");
    },
  },
};
</script>

<style></style>
