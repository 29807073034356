<template>
  <div class="tw-bg-gray-50">
    <MenuDashboard v-if="getMenu" />
    <vHeader />
    <div class="tw-w-full tw-min-h-screen">
      <router-view />
    </div>
  </div>
</template>

<script>
import MenuDashboard from "@/views/dashboard/menu/index.vue";
import vHeader from "@/views/dashboard/header";

import { db } from "@/service/firebase.js";
import { onSnapshot, doc } from "firebase/firestore";
export default {
  data() {
    return {
      user_onSnapshot: function () {},
      totem_onSnapshot: function () {},
    };
  },
  components: {
    MenuDashboard,
    vHeader,
  },
  mounted() {
    this.createHandles();
  },
  beforeDestroy() {
    this.user_onSnapshot();
    this.totem_onSnapshot();
    this.$store.commit("dashboard/erase");
    this.$store.commit("user/erase");
  },
  methods: {
    /*
    async createTotemHandler() {
      // ** ================================
      // ** possui regra de filtro de totem : antiga
      // ** ESSA REGRA NÃO FAZ MAIS SENTIDO
      // ** ================================
      this.$store.commit("dashboard/setTotens", []);
      var userID = this.$store.getters["user/getToken"];
      let queryRef = query(
        collection(db, "userFranqueado"),
        where("userID", "==", `${userID}`)
      );
      var snapshots = await getDocs(queryRef);
      snapshots.forEach((doc) => {
        requestTotemID(doc.data().totemID);
      });

      this.totem_onSnapshot = onSnapshot(queryRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            let payload = change.doc.data();
            payload.id = change.doc.id;
            if (!payload.excluido) {
              this.$store.commit("dashboard/addTotem", payload);
            }
          }
          if (change.type === "modified") {
            this.$store.commit("dashboard/updateTotem", change.doc.data());
          }
          if (change.type === "removed") {
            this.$store.commit("dashboard/removeTotem", change.doc.data().id);
          }
        });
      });
    },*/
    async createUserHandler() {
      const token = this.$store.getters["user/getToken"];
      if (!token) {
        setTimeout(() => {
          this.createUserHandler();
        }, 1000);
        return false;
      }
      const userRef = doc(db, "users", `${token}`);
      this.user_onSnapshot = await onSnapshot(
        userRef,
        (snap) => {
          let payload = snap.data();
          this.$store.commit("user/setUserPayload", {
            nome: payload.nome,
            role: payload.role,
            franqueado_id: payload.franqueado_id,
            email: payload.email,
            status: payload.status,
          });
        },
        (error) => {
          console.log(error);
          this.$root.errorModal(error);
        }
      );
    },
    createHandles() {
      this.createUserHandler();
      // ! não fazia sentido mais essa função this.createTotemHandler();
    },
  },
  computed: {
    getMenu() {
      return this.$store.getters["dashboard/getMenu"];
    },
  },
};
</script>

<style></style>
