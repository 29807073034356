<template>
  <div class="tw-z-20 tw-h-full tw-w-full tw-absolute">
    <div
      :class="{
        'sm:tw--ml-72 tw--ml-96 tw-opacity-5': !premenu,
        'tw-ml-0': premenu,
      }"
      class="tw-transition-all tw-duration-300 tw-bg-gray-800 tw-h-full tw-p-6 tw-px-3 tw-fixed tw-z-30 tw-w-screen sm:tw-w-72"
    >
      <div
        class="tw-text-lg tw-font-normal tw-text-center tw-flex tw-justify-center tw-items-center tw-py-5"
      >
        <div class="tw-pr-2 tw--ml-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-10 tw-w-10 tw-text-pink-200"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="tw-text-white tw-font-bold">Dashboard</div>
      </div>
      <div class="tw-mt-5">
        <div :key="index" v-for="(item, index) in listaMenu">
          <button
            class="rotaPadrao"
            @click="acesso(item)"
            :class="{
              'tw-bg-opacity-50 tw-bg-black': $route.path.includes(item.link),
            }"
          >
            <div class="tw-px-2">
              <v-icon size="20" class="tw-text-pink-200">{{
                item.icon
              }}</v-icon>
            </div>
            <div
              class="tw-font-normal tw-text-md tw-font-sans tw-tracking-wide tw-text-gray-200 tw-mt-1"
            >
              {{ item.nome }}
            </div>
          </button>
        </div>
        <div class="tw-absolute tw-bottom-3 tw-w-11/12 text-center mx-auto">
          <button
            class="rotaPadrao"
            @click="logout()"
            :class="{
              'tw-bg-opacity-50 tw-bg-black': $route.path.includes(),
            }"
          >
            <div class="tw-px-2">
              <v-icon size="20" class="tw-text-pink-200">{{
                "mdi-logout"
              }}</v-icon>
            </div>
            <div
              class="tw-font-normal tw-text-md tw-font-sans tw-tracking-wide tw-text-gray-200 tw-mt-1"
            >
              Logout
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="tw-bg-transparent tw-opacity-10 tw-h-full tw-w-full"
      @click="closeme()"
    ></div>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { db } from "@/service/firebase";

export default {
  data() {
    return {
      timeoutID: "",
      premenu: false,
      listaMenu: [
        {
          nome: "Home",
          link: "/dashboard/home",
          role: ["ADMIN", "TECNICO", "FRANQUEADO"],
          icon: "mdi-home",
          tag: "single",
        },
        {
          nome: "Usuários",
          link: "/dashboard/usuarios",
          role: "ADMIN",
          tag: "single",
          icon: "mdi-account",
        },
        {
          nome: "Totens",
          link: "/dashboard/totem/lista-totem",
          role: ["ADMIN", "TECNICO", "FRANQUEADO"],
          tag: "multimenu",
          icon: "mdi-fit-to-screen",
          toggle: false,
          children: [
            {
              nome: "CADASTRAR",
              icon: "mdi-plus-circle",
              link: "/dashboard/totem/cadastro-totem",
            },
            {
              nome: "LISTA",
              icon: "mdi-view-list",
              link: "/dashboard/totem/lista-totem",
            },
          ],
        },
        {
          nome: "Molduras",
          link: "/dashboard/molduras",
          tag: "single",
          icon: "mdi-image-frame",
          role: ["ADMIN", "TECNICO", "FRANQUEADO"],
        },
        {
          nome: "Logs",
          link: "/dashboard/logs",
          role: ["ADMIN", "TECNICO"],
          tag: "single",
          icon: "mdi-console-network",
        },
        {
          nome: "Vídeos",
          link: "/dashboard/videos",
          role: ["ADMIN", "TECNICO", "FRANQUEADO"],
          tag: "single",
          icon: "mdi-frequently-asked-questions",
        },
      ],
    };
  },
  mounted() {
    this.timeoutID = window.setTimeout(() => {
      this.iniciar();
    }, 50);
    this.user_conectado();
  },
  computed: {
    getMenu() {
      return this.$store.getters["dashboard/getMenu"];
    },
  },
  destroyed() {
    window.clearTimeout(this.timeoutID);
  },
  methods: {
    async user_conectado() {
      const auth = getAuth();
      let user = await getDoc(doc(db, "users", auth.currentUser.uid));
      let funcao = user.data().role.toString();
      this.listaMenu = this.listaMenu.filter((item) => {
        if (item.role.includes(funcao)) {
          return item;
        }
      });
    },
    toggleMenuItem(index, toggle) {
      this.listaMenu[index].toggle = !toggle;
    },
    iniciar() {
      this.premenu = true;
      window.clearTimeout(this.timeoutID);
    },
    acesso(item) {
      this.$root.goto(item.link);
      this.closeme();
    },
    closeme() {
      this.premenu = false;
      this.timeoutID = window.setTimeout(() => {
        this.$store.commit("dashboard/setMenu", false);
      }, 200);
    },
    logout() {
      let auth = getAuth();
      this.$store.commit("user/setAuth", false);
      signOut(auth)
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.rotaPadrao {
  @apply tw-flex tw-items-center tw-w-full tw-p-2 tw-rounded-md tw-text-gray-700 tw-transition tw-duration-300 tw-ease-in-out hover:tw-bg-black hover:tw-bg-opacity-20 tw-text-white tw-mt-1;
}
.submenuItem {
  @apply tw-w-full tw-text-left tw-uppercase tw-p-2 tw-rounded-md tw-text-sm tw-text-white tw-font-normal tw-transition tw-duration-300 tw-ease-in-out hover:tw-bg-pink-600 tw-cursor-pointer;
  .ativo {
    @apply tw-border-pink-600;
  }
}
</style>
